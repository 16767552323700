import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import _ from "lodash";
import { resetPassword, clearError } from "./../store/actions";
import ResetPasswordForm from "./components/ResetPasswordForm";
import {
  ActionModal,
  CardBody,
  FlashMessages,
  FormattedError,
  FormattedSuccess,
} from "./../../../components/partials/controls";
import { Card } from "react-bootstrap";
import { Breadcrumbs } from "@mui/material";
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import pages from 'src/modules/admin/containers/home/routes/pages.js'
import { formatErrorMessage, formatMessageError } from "src/helpers";
import useIsDenied from "src/components/partials/controls/forms/inputs/hooks/useIsDenied";
import { VaccinationManagement } from "src/constants/ModulesPermissions";
import { DASHBOARD } from "src/constants/AppPermissions";
import { VIEW } from "src/constants";
import adminRoutes from "../../admin/routes"

const ResetPassword = ({intl}) => {
  const dispatch = useDispatch();
 
  const { error, isLoading,success,firstLogin,user_id,isSuperuser,isDssb,permissions } = useSelector(
    (state) => ({
      error: state.common.auth.error,
      isLoading: state.common.auth.isLoading,
      success: state.common.auth.success,
      firstLogin: state.common.auth.currentUser?.firstLogin,
      user_id: state.common.auth.currentUser.pk,
      isSuperuser: state.common.auth.isSuperuser,
      isDssb: state.common.auth.isDssb,
      permissions:state.common.auth.permissions


    }),
    shallowEqual
  );
  const cantViewDashboard = useIsDenied({
    can: VaccinationManagement.module[DASHBOARD].permissions[VIEW],
    isSuperuser,
    isDssb,
    permissions,
  });
  let homePath =  cantViewDashboard  === false ? adminRoutes.home.path : adminRoutes.vaccination.path


  const history = useHistory()
  // dispatch(clearError())

  
  useEffect(()=>{
    if (firstLogin === false){
      history.push(pages.resetPassword.path)
    } 
    else if (success) {
      history.push(pages.resetPassword.path)

      // history.push(homePath)
    }
  },[firstLogin,success])

  const onSubmit = (values) => {
    const fieldValues = _.flow([
      Object.entries,
      (arr) =>
        arr.filter(
          ([key, value]) =>
            value != null &&
            value.length >= 1 &&
            key !== "confirmPassword"
        ),
      Object.fromEntries,
    ])(values);
    dispatch(resetPassword({ ...fieldValues,user_id}));
  };

  console.log({...error})
  const breadcrumbs = [
    <Link
      key="/auth/login"
      to="/admin/vaccination"
      style={{ fontFamily: "Poppins", fontStyle: "normal" }}
    >
    <FormattedMessage id='MENU.ROUTINEVACCINATION' />    </Link>,
    <Link
      key="/auth/forgot-password"
      style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: 700 }}
      to="#"
    >
          <FormattedMessage id="AUTH.RESET_PASSWORD.TITLE" />

    </Link>,
  ];
  return (
    <Card className="my-5">
      <CardBody>
      <div className="text-center mb-lg-20">
        <h3 className="mt-6">
        <FormattedMessage id="AUTH.RESET_PASSWORD.TITLE" />
        </h3>
      </div>
       <ResetPasswordForm onSubmit={onSubmit} isLoading={isLoading}/>
      {/* <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs> */}
      </CardBody>
      <FlashMessages
        successMsg={[
          {
            condition: success,
            label: intl.formatMessage({ id: "AUTH.FORGOT.SUCCESS" }),
          }
        ]}
        error={error}
        onClose={clearError}
        onCloseError={clearError}
      />
    </Card>
  );
};

export default injectIntl(ResetPassword);
